/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import jwt_decode from "jwt-decode";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Disclaimer from "../../components/common/Disclaimer";
import CustomTable from "../../components/common/Table/CustomTable";
import {
  newStyles,
  overRideStyles,
} from "../../components/common/Table/TableStyle";
import { TableColumns } from "../../components/common/Table/TableColumns";

import DownArrow from "../../assets/DownArrow.svg";
import UpArrow from "../../assets/UpArrow.svg";
import EmptyLoan from "../../assets/EmptyLoan.svg";

import {
  filterKeyOrgMap as orgMap,
  filterKeyLatMap as latMap,
  filterKeyILPMap as iLPMap,
  filterKeyApraisalMap as appraisalMap,
  isILP,
  // getURL,
  sanitizeData,
  getCookie,
} from "../../utils/Common";
// import { axiosClient } from "../../utils/utility";

import { ObjectType } from "../../types/tableTypes";

import { RootContext } from "../../context/RootContext";

import "./listView.css";
import { filterDataResponse, postRowData } from "../../services/network";

function ListView() {
  const { setDynamicLoader, showTermSheet, setCurrentPath } = useContext(RootContext);

  const [isCollapsed, setCollapsed] = useState<boolean>(false);
  const [filtersLoader, setFiltersLoader] = useState({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loanDetailsSelected, setLoanDetailsSelected] = useState<any[]>([]);
  const [unselectedLoanIds, setUnselectedLoanIds] = useState<string[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [copyValueArray, setCopyValueArrayState] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [newestLoanArrow, setNewestLoanArrow] = useState<boolean>(false);
  const [selectedLoanType, setSelectedLoanType] = useState<any>([]);
  const [dateSelected, setDateSelected] = useState("");
  const [newTableColumns, setNewTableColumns] =useState<any[]>(TableColumns)

  const checkboxRef: any = useRef([]);
  const { t } = useTranslation();
  const classes: any = newStyles();
  const overRidenStyles = overRideStyles();
  const navigate = useNavigate();

  const sortConfig: any = {};
  // useCallback
  // const onCollapseColumn = useCallback(() => {
  //   setCollapsed((isCollapsed: boolean) => !isCollapsed);
  // }, []);

  const onSort = useCallback((config: any) => {
    const columnKey = Object.keys(config)?.[0];
    const val = config?.[columnKey];
  }, []);

  const handleFilterToggle = useCallback((columnName: string) => {
    if (!(filterKeyMap[columnName] && columnName)) return;
    setFiltersLoader((filtersLoader) => ({
      ...filtersLoader,
      [columnName]: true,
    }));
  }, []);

  const findLatestElement = (data: any, key: any) => {
    return data.reduce((latest: any, current: any) => {
      return new Date(current[key]) > new Date(latest[key]) ? current : latest;
    }, data[0]);
  }

  useEffect(() => {
      const newColumns =  TableColumns.map((col: any) => {
        if(col.id ==='termSheet') {
          return {...col, hidden: !showTermSheet}
        } else {
          return {...col}
        }
      })
      setNewTableColumns(newColumns.filter((e: any) => !e.hidden))
      setCurrentPath(window.location.pathname)
  },[showTermSheet])


  // const handleCheckBoxChange = useCallback(
  //   (config: { [key: string]: string[] }, headerFilterTags: any) => { },
  //   []
  // );

  // const onRowSelect = useCallback(
  //   (e: any, ids: any, data?: any) => {
  //     if (e.target.checked) {
  //       setSelectedRows((selectedRows) => [...selectedRows, ...ids]);
  //       // setLoanDetailsSelected((selectedLoanDetails) => {
  //       //   return [...selectedLoanDetails, ...data];
  //       // });
  //       setUnselectedLoanIds((loanIds: string[]) =>
  //         loanIds.filter((item: any) => item !== ids[0])
  //       );
  //     } else {
  //       setSelectedRows((selectedRows: string[]) => {
  //         return selectedRows.filter((item: any) => item !== ids[0]);
  //       });
  //       // setLoanDetailsSelected((selectedLoanDetails) => {
  //       //   return selectedLoanDetails.filter(
  //       //     (item: any) => item?.toorakLoanId !== ids[0]
  //       //   );
  //       // });
  //       setUnselectedLoanIds((loanIds: string[]) => [...loanIds, ...ids]);
  //     }
  //   },
  //   [setUnselectedLoanIds]
  // );

  // const selectAllRows = useCallback((checked: boolean) => { }, []);

  // useMemo
  const tableFilterConfig: any = useMemo(() => {
    return { "loan.loanType.raw": ["Bridge Loan"] };
  }, []);

  const [filterData, setFilterData] = useState<any>({});

  const filterKeyMap: ObjectType = useMemo(
    () => ({
      ...orgMap,
      ...latMap,
      ...iLPMap,
      ...appraisalMap,
    }),
    []
  );

  const tableSearchConfig = useMemo(() => {
    const filters = tableFilterConfig?.filterSelections ?? {};
    const filterKeys = Object.keys(filters);
    let tableSearchFilters = {};
    filterKeys.forEach((key: string) => {
      if (key.includes("_searchBox")) {
        const filterValue = filters?.[key].join();
        tableSearchFilters = {
          ...tableSearchFilters,
          [key.replace("_searchBox", "")]: filterValue,
        };
      }
    });
    return tableSearchFilters;
  }, [tableFilterConfig]);

  const tableSortConfig = useMemo(() => {
    return { [sortConfig?.sortColumn]: sortConfig?.sort };
  }, [sortConfig]);

  // const noOfFrozenCols: number = useMemo(() => {
  //   return TableColumns.filter((itm: any) => itm.frozen)?.length ?? 0;
  // }, [TableColumns]);

  const isSingleLoanTypeSelected = useMemo(() => {
    return (
      tableFilterConfig?.filterSelections?.["loan.loanType.raw"]?.length === 1
    );
  }, [tableFilterConfig]);

  function handleSearch(e: React.FormEvent) {
    e.preventDefault();
    setCurrentPage(1);
    getRowData(1);
  }

  function noApplicationData() {
    return (
      <>
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <img src={EmptyLoan} alt="empty-loan" />
          </Grid>
        </Grid>
        <Grid container className="mb-40">
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <h2>{t("noApplicationText")}</h2>
          </Grid>
        </Grid>
        <Grid container className="mb-40">
          <Grid item xs={5}></Grid>
          <Grid item xs={6}>
            <Button
              className="mr-12"
              variant="contained"
              onClick={openEvaluateLoan}
              data-testid="evaluate-new-loan-button"
            >
              {t("evaluateNewLoan")}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Footer showFooterText={true} />
          </Grid>
        </Grid>
      </>
    );
  }

  function applicationTable() {
    return (
      <>
        <Grid container>
          <Disclaimer
            boldText={t("elNoteText")}
            children={<span>{t("evaluateLoanDisclaimer")}</span>}
            customClass="disclaimerBox"
            showClose={true}
          />
          <Grid item xs={12} sm={6}>
            <h3>
              {t("applications")}
              {totalDataCount}
            </h3>
          </Grid>
          <Grid item xs={12} sm={6} className="div-end">
            <p>{t("newestFirst")}</p>
            <IconButton
              sx={{
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={() => {
                handleSortArrow();
              }}
              data-testid="arrow-icon"
            >
              <img
                src={newestLoanArrow ? DownArrow : UpArrow}
                alt="arrow.svg"
              />
            </IconButton>
          </Grid>
        </Grid>
        <CustomTable
          columnsData={newTableColumns}
          rowsData={rowsData}
          classes={classes}
          isCollapsed={isCollapsed}
          filtersLoader={filtersLoader}
          tableFilterConfig={tableFilterConfig?.filterSelections}
          tableSearchConfig={tableSearchConfig}
          tableSortConfig={tableSortConfig}
          filterData={filterData}
          onSort={onSort}
          handleFilterToggle={handleFilterToggle}
          // handleCheckBoxChange={handleCheckBoxChange}
          // collapsColumnIndex={noOfFrozenCols - 1}
          expandColumnIndex={0}
          // onCollapseColumn={onCollapseColumn}
          // showCollapseButton={noOfFrozenCols > 1}
          // frozenCols={noOfFrozenCols}
          showNoDataMsg
          overRideStyles={overRidenStyles}
          onSelectedColor="#F6FBFF"
          showCheckBox={false}
          // onRowSelect={onRowSelect}
          // selectAllRows={selectAllRows}
          allSelected={allRowsSelected && unselectedLoanIds.length === 0}
          selectedLoans={selectedRows}
          fewRowsSelected={
            unselectedLoanIds?.length > 0 && selectedRows?.length > 0
          }
          disableSelectAll={isILP() ? false : !isSingleLoanTypeSelected}
          // handleUpdateSelectedCheckboxes={handleUpdateSelectedCheckboxes}
          selectAllTootltipText={
            isSingleLoanTypeSelected || isILP()
              ? ""
              : "Select a loan type to export loans"
          }
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          totalDataCount={totalDataCount}
          onLinkClick={onLinkClick}
          getRowData={getRowData}
          handleCheckboxOnchange={handleCheckboxOnchange}
          selectedLoanType={selectedLoanType}
          uncheckAll={uncheckAll}
          checkboxRef={checkboxRef}
          handleRateAsOfFilter={handleRateAsOfFilter}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
        />
      </>
    );
  }

  const handleCheckboxOnchange = (name: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedLoanType((prevItems: any) => [...prevItems, name]);
    } else {
      setSelectedLoanType((prevItems: any) =>
        prevItems.filter((item: any) => item !== name)
      );
    }
  };

  const handleRateAsOfFilter = (selectedDate: any) => {
    if (selectedDate) {
      const formattedDate = moment(selectedDate).format("MM-DD-YYYY");
      setDateSelected(formattedDate);
    }
  };

  // uncheck the checkboxes in filter
  const uncheckAll = () => {
    const checkboxes = checkboxRef?.current.filter(
      (item: any) => item !== null
    );
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
    setSelectedLoanType([]);
  };

  const getRowData = async (page: any = currentPage, sortBy?: any) => {
    setDynamicLoader(true);
    try {
      const sortValue = newestLoanArrow ? "asc" : "desc";
      const sortBy = [
        {
          key: "createdAt",
          sort: sortValue,
        },
      ];
      let value: any = searchText;
      let key = "data.applicationName";
      if (!isNaN(+searchText)) {
        key = "data.evaluationID";
        value = searchText;
      }

      const searchKey = {
        key,
        value,
      };

      const accessToken: string = getCookie("sizer_access_token") || "";
      const decodedData: ObjectType = jwt_decode(accessToken);
      let filters: any = {
        "data.applicationName": { $exists: true },
        "data.evaluationID": { $exists: true },
        createdByEmail: decodedData?.email,
      };

      if (!Object.keys(filterData)?.length) {
        getFilterData(filters)
      }
      const loanTypeFilter: any = [];
      let ratesFilter;

      if (selectedLoanType) {
        selectedLoanType.map((ele: string) => {
          loanTypeFilter.push({ "data.productType": ele });
        });
      }

      if (dateSelected) {
        ratesFilter = {
          "data.ratesAsOf": moment(dateSelected, "MM-DD-YYYY").format(
            "DD-MM-YYYY"
          ),
        };
      }

      if (loanTypeFilter.length) {
        filters["$or"] = loanTypeFilter;
      }

      if (ratesFilter) {
        filters = {
          ...filters,
          ...ratesFilter,
        };
      }

      // const url = getURL("online-sizer/fetch/query");

      const body: ObjectType = {
        page,
        limit: rowsPerPage,
        sortBy,
        filters,
      };

      if (searchText.length) {
        body.search = searchKey;
      }

      const { data } = await postRowData(body);
    
      const tempResult: any = [];
      data?.data?.records.map((data: ObjectType) => {
        const formattedCreatedOn = data?.createdAt
          ? moment(new Date(data.createdAt)).format("MM/DD/YYYY")
          : "";
        let rateAsOf = data?.data?.ratesAsOf?.replace(/[a-zA-Z]/g, "") ?? "";
        rateAsOf = rateAsOf
          ? moment(rateAsOf, "DD-MM-YYYY").format("MM/DD/YYYY")
          : "";
        const currentProperty = Array.isArray(data?.data?.propertyDetails)
          ? data?.data?.propertyDetails[0]
          : {
            propertyLocation: data?.data?.propertyLocation,
          };
        const termSheet = data?.termSheetVersions?.length && findLatestElement(data?.termSheetVersions, "createdAt")
        tempResult.push({
          evaluationId: data?.data?.evaluationID || "",
          applicationName: data?.data?.applicationName || null,
          loanType: data?.data?.productType,
          loanAmount: sanitizeData(
            (data?.data?.loanEconomics?.originalLoanAmount ||
              data?.data?.loanEconomics?.initialLoanAmount ||
              data?.data?.loanEconomics?.borrowerRequested) ??
            null,
            true
          ),
          interestRate:
            (data?.data?.loanEconomics?.interestRate ||
              data?.data?.loanEconomics?.originalInterestRate) ??
            null,
          createdOn: formattedCreatedOn,
          rateAsOf,
          streetAddress: currentProperty?.propertyLocation?.address,
          city: currentProperty?.propertyLocation?.city,
          postal: currentProperty?.propertyLocation?.postalCode,
          loanId: data?.loanId,
          productType: data?.data?.loanType,
          termSheet: termSheet?.termSheetVersion ? `${t("termSheetV")}${termSheet?.termSheetVersion}` : "Not Generated",
          termSheetVersion: termSheet?.termSheetVersion
        });
      });
      setTotalDataCount(data?.data?.total);
      setRowsData(tempResult);
      // setRowsPerPage(data?.data?.total);
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
    }
  };

  // const handleUpdateSelectedCheckboxes = (
  //   columnKey: string,
  //   selectedIds: any,
  //   label: any
  // ) => {
  //   setCopyValueArrayState(selectedIds);
  //   // handleAutoCompleteSearch(columnKey, selectedIds, label);
  // };

  const openEvaluateLoan = () => {
    navigate("/evaluate-loan");
  };

  useEffect(() => {
    getRowData(currentPage);
    setCurrentPath(window.location.pathname)
  }, [currentPage, newestLoanArrow, rowsPerPage]);

  const onLinkClick = (loanType: any, loanId: any, productType?: any) => {
    const redirectLoanType =
      loanType === "Bridge Loan"
        ? `${loanType}/${productType}`.replace(/ +/g, "")
        : loanType.replace(/ +/g, "");
    return `${window.location.origin}/loan/${redirectLoanType}/${loanId}`;
    // navigate(`/loan/${redirectLoanType}/${loanId}`);
  };

  const handleSortArrow = () => {
    setNewestLoanArrow(!newestLoanArrow);
  };

  const getFilterData = (filters: ObjectType) => {
    filterDataResponse(filters)
      .then((res: any) => {
        const fRes: any = {};
        if (res?.data?.data) {
          Object.values(res.data.data).forEach((data: any) => {
            if (data._id) {
              fRes[data._id] = data;
            }
          });
          setFilterData({ loanType: fRes });
        }
      })
      .catch((err: ObjectType) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Header
        showEvaluateNewLoan={
          rowsData.length ||
          totalDataCount ||
          !selectedLoanType.length ||
          !dateSelected
        }
        showSearch={true}
        showBorder={true}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      {rowsData.length ||
        totalDataCount ||
        !selectedLoanType.length ||
        !dateSelected
        ? applicationTable()
        : noApplicationData()}
    </div>
  );
}

export default ListView;
